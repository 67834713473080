"use client";

import { useUser } from "@auth0/nextjs-auth0/client";
import React from "react";

import Spinner from "~/components/spinner";
import { TextField } from "~/components/v2/TextField";
import { useGetUser } from "~/hooks/businessUsers/useGetUser";
import { useGetJobOpenings } from "~/hooks/jobOpening/useGetJobOpenings";
import useDebounce from "~/hooks/useDebounce";
import { useSidebar } from "~/scalis-components/core/sidebar";
import { AppMetadata } from "~/types/auth";
import { cn } from "~/utils/cn";
import { JobEntry } from "..";

export const JobMenu: React.FC = () => {
  const { collapsed, focused } = useSidebar();

  const [search, setSearch] = React.useState("");
  const debouncedSearch = useDebounce(search, 500);

  const { user } = useUser();

  const { data: userData, isLoading: loadingUser } = useGetUser(
    (user?.app_metadata as AppMetadata)?.id,
  );

  const { data, isLoading: loadingJobs } = useGetJobOpenings(
    {
      limit: 50,
      filters: { statusIn: [1], companyId: userData?.companyId },
      showPublic: true,
      search: debouncedSearch,
    },
    undefined,
    !!userData,
  );

  const loading = loadingUser || loadingJobs;

  return (
    <div
      className={cn("flex flex-1 overflow-auto", {
        hidden: collapsed && !focused,
      })}
    >
      <div className="flex flex-col w-full h-full gap-4 overflow-y-hidden overflow-x-clip">
        <TextField
          startAdornment={
            <i aria-hidden className="text-sm fa-regular fa-search text-icons-secondary" />
          }
          placeholder="Search"
          value={search}
          onChange={e => setSearch(e.target.value)}
          className="bg-white h-9 rounded-xl border-neutral-10 pl-9 placeholder:text-neutral-tertiary hover:bg-neutral-10"
          containerClassName="px-4 py-1"
        />
        <ul className="flex flex-col flex-1 h-full gap-1 overflow-y-auto overflow-x-clip">
          {loading ? (
            <Spinner className="relative" />
          ) : (
            <div className="flex flex-col gap-2 px-4">
              {data?.docs?.map(job => <JobEntry key={job.id} {...job} />)}
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};
