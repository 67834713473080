import { useContext } from "react";

import { SidebarContext } from ".";

export const useSidebar = () => {
  const { collapsed, focused, changeFocused, toggleCollapsed } =
    useContext(SidebarContext);

  return {
    collapsed,
    focused,
    toggleCollapsed,
    changeFocused,
  };
};
