import { EmptyStateProps } from "./empty-state.types";
import { emptyState } from "./empty-state.styles";
import { cn } from "~/utils/cn";

export const EmptyState: React.FC<EmptyStateProps> = ({
  icon,
  iconSize,
  iconContainerClassName,
  message,
  title,
  children,
}) => {
  const {
    containerStyle,
    childrenStyle,
    iconStyle,
    iconContainerStyle,
    contentStyle,
    messageStyle,
    titleStyle,
  } = emptyState();
  const iconContainerSizeFallback = iconContainerClassName ?? "size-22";
  const iconSizeFallback = iconSize ?? "text-2xl";

  return (
    <div className={containerStyle()}>
      {icon && (
        <div className={cn(iconContainerStyle(), iconContainerSizeFallback)}>
          <i
            className={cn(iconStyle(), icon, iconSizeFallback)}
            data-testid="empty-state-icon"
          ></i>
        </div>
      )}
      <div className={contentStyle()}>
        <h2 className={titleStyle()}>{title}</h2>
        <p className={messageStyle()}>{message}</p>
      </div>
      {children && <div className={childrenStyle()}>{children}</div>}
    </div>
  );
};
