"use client";

import * as React from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { cn } from "~/utils/cn";
import { DynamicIcon } from "..";
import { useSidebar } from "../../context";
import { NavItemProps } from "./nav-item.types";

export const NavItem: React.FC<NavItemProps> = ({
  label,
  href,
  icon,
  activePath,
  isActive = false,
}) => {
  const pathname = usePathname();
  const { collapsed, focused } = useSidebar();

  let isActiveRoute = false;
  if (isActive) {
    isActiveRoute = isActive;
  } else if (activePath != null) {
    isActiveRoute = pathname?.includes(activePath) ?? false;
  }

  return (
    <li
      className={cn(
        "z-[1002] flex w-full items-center rounded-xl hover:bg-surface-brand-rest",
        {
          "bg-surface-brand-hover": isActiveRoute,
          "w-10": collapsed && !focused,
        },
      )}
    >
      <Link
        href={href}
        className="flex w-full items-center justify-between p-2 data-[active=true]:text-brand-primary-rest"
        prefetch={true}
        aria-current={isActiveRoute ? "page" : undefined}
        data-active={isActiveRoute}
      >
        <div className="flex items-center gap-2">
          <DynamicIcon icon={icon} isActive={isActiveRoute} />

          <span
            className={cn(
              "max-w-full whitespace-nowrap text-sm text-neutral-primary opacity-100 transition-all duration-100",
              {
                "font-medium text-brand-primary-rest": isActiveRoute,
                "max-w-0 opacity-0": collapsed && !focused,
              },
            )}
          >
            {label}
          </span>
        </div>
      </Link>
    </li>
  );
};
