"use client";

import { JobListingIcon, useSidebar } from "~/scalis-components/core/sidebar";
import { cn } from "~/utils/cn";

import { CreateJobButton, SortByMenu } from "./components";

export const HeaderActions = () => {
  const { collapsed, focused } = useSidebar();

  return (
    <header className="flex items-center justify-between px-4 pt-4 pb-2">
      <div className="flex h-[42px] items-center gap-2">
        <JobListingIcon />

        <h3
          className={cn("block text-sm text-neutral-primary", {
            hidden: collapsed && !focused,
          })}
        >
          Job Listings
        </h3>
      </div>
      <div
        className={cn("flex gap-2", {
          hidden: collapsed && !focused,
        })}
      >
        <SortByMenu />
        <CreateJobButton />
      </div>
    </header>
  );
};
