import { HeaderActions, JobMenu } from "./components";
import { JobListingsProvider } from "./context";

export const JobListings = () => {
  return (
    <JobListingsProvider>
      <div className="flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
        <HeaderActions />
        <JobMenu />
      </div>
    </JobListingsProvider>
  );
};
