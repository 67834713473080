"use client";

import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/scalis-components/core";
import logger from "~/utils/logger";

import { SORT_BY_OPTIONS } from "../../../../job-listings.constants";

export const SortByMenu = () => {
  const handleSortBy = (sortBy: string) => {
    logger.info(sortBy);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          className="rounded-[4px] bg-transparent p-1 text-lg text-icon-neutral-10 ring-0 hover:bg-button-subtle-hover"
          startAdornment="fa-regular fa-filter"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {SORT_BY_OPTIONS.map(option => (
          <DropdownMenuItem
            key={option.value}
            onClick={() => handleSortBy(option.value)}
          >
            {option.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
