"use client";

import * as React from "react";
import { cn } from "~/utils/cn";
import { useSidebar } from "../../context";
import { containerClassName, navClassName } from "./container.styles";

export const Container: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => {
  const { collapsed, focused } = useSidebar();

  // TODO: Add hover logic to the sidebar, current is not working properly
  // const hoverTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  // const handleFocus = (isFocused: boolean) => {
  //   if (collapsed) {
  //     changeFocused(isFocused);
  //   }
  // };
  // const handleMouseEnter = () => {
  //   hoverTimeoutRef.current = setTimeout(() => handleFocus(true), 1000);
  // };
  // const handleMouseLeave = () => {
  //   if (hoverTimeoutRef.current) {
  //     clearTimeout(hoverTimeoutRef.current);
  //     hoverTimeoutRef.current = null;
  //   }
  //   handleFocus(false);
  // };

  return (
    <aside
      className={cn(containerClassName({ collapsed }), className)}
      {...props}
    >
      <nav
        className={cn(navClassName({ focused }))}
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
        tabIndex={collapsed ? 0 : -1}
        {...props}
      >
        {children}
      </nav>
    </aside>
  );
};
