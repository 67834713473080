"use client";

import { useMemo } from "react";

import { cn } from "~/utils/cn";
import { DynamicIcon } from "..";
import { SidebarCloseIcon, SidebarOpenIcon } from "../..";
import { useSidebar } from "../../context";
import { COLLAPSE_BUTTON_LABEL } from "./collapse-button.constants";

export const CollapseButton = () => {
  const { collapsed, focused, toggleCollapsed } = useSidebar();

  const handleToggleCollapsed = () => {
    toggleCollapsed();
  };

  const sideIcon = useMemo(() => {
    return collapsed ? SidebarCloseIcon : SidebarOpenIcon;
  }, [collapsed]);

  return (
    <button
      className={cn(
        "flex w-full items-center justify-start rounded-xl p-2 hover:bg-surface-brand-rest",
        {
          "w-10": collapsed && !focused,
        },
      )}
      onClick={handleToggleCollapsed}
    >
      <div className="flex items-center gap-2">
        <DynamicIcon icon={sideIcon} />
        <span
          className={cn(
            "max-w-full whitespace-nowrap text-sm text-neutral-primary opacity-100 transition-all duration-300",
            {
              "max-w-0 opacity-0": collapsed && !focused,
            },
          )}
        >
          {COLLAPSE_BUTTON_LABEL}
        </span>
      </div>
    </button>
  );
};
