import { tv } from "tailwind-variants";

// TODO: If this top margin mess up other layouts, we need to find a better way to handle the sidebar top margin.
export const containerClassName = tv({
  base: "relative z-50 flex h-auto w-full min-w-full transition-all md:w-[284px] md:min-w-[284px]",
  variants: {
    collapsed: {
      true: "w-[58px] min-w-[58px]",
    },
  },
});

export const navClassName = tv({
  base: "flex overflow-hidden min-h-screen h-full w-full flex-col rounded-tr-2xl border-gray-200 bg-white transition-all",
  variants: {
    focused: {
      true: "absolute top-0 left-0 w-[284px] min-w-[284px] shadow-lg overflow-hidden",
    },
  },
});
