"use client";

import { createContext, useState } from "react";

import {
  SidebarContextProps,
  SidebarProviderProps,
} from "./sidebar-context.types";

export const SidebarContext = createContext({} as SidebarContextProps);

export const SidebarContextProvider: React.FC<SidebarProviderProps> = ({
  children,
}) => {
  const [focused, setFocused] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const changeFocused = (isFocused: boolean) => {
    setFocused(isFocused);
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <SidebarContext.Provider
      value={{
        collapsed,
        focused,
        changeFocused,
        toggleCollapsed,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
